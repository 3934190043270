import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { obtenirPage } from "../js/client-es/utils"
import fragmentsImages from "../js/client-es/fragments/fragments-images"
import fragmentsPages from "../js/client-es/fragments/fragments-pages"
import LayoutFR from "../composants/layout/fr/LayoutFR"
import Section from "../composants/layout/Section"

export const requete = graphql`
  {
    pageConditionGeneralesDeVente: allPagesCompilationYaml(
      filter: { code: { eq: "conditionsGeneralesDeVente" } }
    ) {
      ...FragmentPageYaml
    }
  }
`

export default function ConditionsGeneralesDeVente() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pageConditionGeneralesDeVente")

  return (
    <LayoutFR page={page}>
      <section className="section-conteneur">
        <p>
          Les présentes conditions générales de vente définissent les droits et
          obligations des parties dans le cadre de la vente de produits par
          Bastien Foucher à ses clients par l’intermédiaire de son site
          internet.
        </p>
        <p>
          Le client déclare avoir pleinement pris connaissance des présentes
          conditions générales de vente et accepté les droits et obligations
          ci-après énoncés.
        </p>
      </section>
      <Section titre="Livre électronique « Astrophotographie avec un réflex »">
        <h3>Définitions</h3>
        <ul>
          <li>L’Auteur : Bastien Foucher</li>
          <li>Site Internet : www.bastienfoucher.com</li>
          <li>
            Le Produit : le livre électronique « Astrophotographie avec un
            réflex »
          </li>
          <li>
            Le Client : toute personne réalisant une commande du Produit auprès
            de l’Auteur.
          </li>
        </ul>

        <h3>Prix</h3>
        <p>
          Le produit est facturé en euros sur la base des prix indiqués sur le
          site Internet au moment de la commande. Le prix indiqué est le prix
          définitif, toutes taxes comprises.
        </p>

        <h3>Modalités de paiement</h3>
        <p>
          Le Produit peut être payé par carte bancaire ou par Paypal. Le débit
          de la carte / compte Paypal est effectué dès la commande passée sur le
          Site Internet. L’Auteur ne sera pas tenu de procéder à la livraison du
          Produit tant que le paiement n’a pas été intégralement versé.
        </p>

        <h3>Livraison</h3>
        <p>
          Immédiatement après le règlement de la commande, un courrier
          électronique contenant un lien de téléchargement vers un fichier PDF
          du Produit acheté est envoyé au client.
        </p>

        <h3>Mises à jour</h3>
        <p>
          Le Client dispose à compter de la date de la commande d’un délai de
          deux ans de mise à jour du Produit. Passé ce délai, le Client devra
          recommander le Produit pour bénéficier des nouvelles mises à jour. Les
          Clients bénéficiaires de la mise à jour seront invités à se mettre à
          jour par courrier électronique.
        </p>
        <p>
          L’Auteur ne s’engage pas sur une fréquence précise de mises à jour du
          Produit.
        </p>

        <h3>Remboursement</h3>
        <p>
          Si le Produit ne porte satisfaction, l’Auteur peut être amené à
          rembourser le prix d’achat au Client après échange autour du motif
          d’insatisfaction.
        </p>

        <h3>Piratage</h3>
        <p>
          Le Client s’engage à ne pas partager illégalement le Produit. Dans le
          cas contraire, le Client s’expose à des poursuites judiciaires.
        </p>

        <h3>Litiges</h3>
        <p>
          En cas de litige, l’Auteur et le Client s’engagent à trouver une
          solution à l’amiable. Dans le cas contraire, le litige sera porté
          devant le tribunal compétent.
        </p>
      </Section>
      <Section titre="Tirages d’art">
        <h3>Définitions</h3>
        <ul>
          <li>L’Auteur : Bastien Foucher</li>
          <li>Site Internet : www.bastienfoucher.com</li>
          <li>
            Le Client : toute personne réalisant une commande d’un tirage auprès
            de l’Auteur.
          </li>
        </ul>

        <h3>Produits</h3>
        <p>
          Tout tirage proposé à la vente sur ce site. Ce sont des œuvres d’art
          originales, signées et numérotées dans la limite de 30 exemplaires
          tous formats confondus. Les tirages sont réalisés à la demande, aucun
          stock n’est géré par l’Auteur.
        </p>
        <h3>Certificats d’authenticité</h3>
        <p>
          Tous les tirages proposés à la vente sont certifiés et numérotés.
          <ul>
            <li>
              Les certificats d’authenticité des tirages seuls sont envoyés au
              Client par courrier électronique.
            </li>
            <li>
              Les certificats d’authenticité des tirages d’art (contre-collages
              sur aluminium-Dibond et encadrement en caisse américaine) seront
              envoyés par pli séparé du tirage au domicile du Client.
            </li>
          </ul>
        </p>
        <h3>Prix</h3>
        <p>
          Les Produits sont facturés en euros sur la base des prix indiqués sur
          le Site Internet au moment de la commande. Les prix indiqués sont les
          prix définitifs, toutes taxes comprises et sont mentionnés dans la
          confirmation de commande adressée par l’Auteur au Client par courrier
          électronique. Les produits restent propriété de l’Auteur jusqu’au
          paiement intégral de la commande. Les prix affichés s’entendent frais
          de port vers la France inclus.
        </p>
        <p>
          En cas de commande hors de l’Union Européenne, les droits de douanes
          ou taxes pouvant être exigés sont à la charge du Client.
        </p>

        <h3>Modalités de paiement</h3>
        <p>
          Les produits peuvent être réglés par carte bancaire ou par Paypal. Le
          débit de la carte bancaire / compte Paypal est effectué dès la
          commande passée sur le Site Internet. L’Auteur ne sera pas tenu de
          procéder à la livraison des produits commandés tant que le paiement
          n’a pas été intégralement versé.
        </p>

        <h3>Livraison</h3>
        <p>
          L’Auteur ne s’engage pas sur un délai de livraison précis. Il s’engage
          toutefois à informer le plus régulièrement possible le Client de
          l’avancée de l’exécution de sa commande.
        </p>
        <p>
          Les délais de livraison généralement constatés sont les suivants :
        </p>
        <ul>
          <li>
            Tirages simples sans prestation de contre-collage ou d’encadrement :
            moins de 10 jours après paiement de la commande.
          </li>
          <li>
            Tirages avec prestation de contre-collage sur aluminium-Dibond :
            moins de 15 jours après paiement de la commande.
          </li>
          <li>
            Tirages avec prestation d’encadrement en caisse américaine : moins
            de 20 jours après paiement de la commande.
          </li>
        </ul>
        <p>
          L’Auteur ne peut être tenu pour responsable d’une livraison d’un colis
          endommagé. Cette responsabilité incombe au transporteur. Si le cas se
          produit, le Client doit refuser la livraison du colis, l’indiquer sur
          le bon de livraison et envoyer une lettre recommandée au transporteur
          dans les trois jours, jours fériés non compris. L’Auteur doit être
          informé de cette démarche. L’Auteur ne peut être tenu pour responsable
          d’un retard de livraison ou d’une non livraison pour cause de perte de
          colis ou de force majeure (grève, intempéries météo…).
        </p>
        <h3>Retours et remboursements</h3>
        <p>
          Si le Produit réceptionné par le Client n’est pas conforme à la
          commande ou s’il présente des défauts, le Client dispose d’un droit de
          retour. Le Client doit alors informer l’Auteur de la non-conformité du
          Produit avec la commande et doit le retourner dans son emballage
          d’origine. Puis, après accord avec le Client, le Produit sera dans ce
          cas :
          <ul>
            <li>
              Soit échangé par l’Auteur qui prendra en charge tous les frais
              engendrés.
            </li>
            <li>Soit remboursé.</li>
          </ul>
        </p>
        <p>
          Les Produits proposés à la vente ne sont pas en stock et sont réalisés
          à la demande du Client. Si le Client change d’avis après la commande,
          le droit de rétraction prévu à l’article L121-20 du Code de la
          Consommation ne peut pas être exercé conformément à l’article
          L121-20-2 du Code de la Consommation.
        </p>
        <h3>Litiges</h3>
        <p>
          En cas de litige, l’Auteur et le Client s’engagent à trouver une
          solution à l’amiable. Dans le cas contraire, le litige sera porté
          devant le tribunal compétent.
        </p>
      </Section>
    </LayoutFR>
  )
}
